import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
declare var jquery: any;
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'login-init',
  templateUrl: './login.component.html'
})
export class LoginInitComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) {
   }

  ngOnInit() {
  } 

  authen(e){
    $('.loading').show();
    e.preventDefault();
      var username = e.target.elements[0].value;
      var password = e.target.elements[1].value;
      var outsider = e.target.elements[2].checked;
      var param = {'username':username, 'password':password, 'outsider': outsider};
      console.log(param);
      this.authService.auth(param).subscribe(res=>{
        console.log(res.data);
        this.authService.setCustomer(res.data);
        setTimeout(() => {
          $('.loading').hide();
        }, 500);
      }, error => {
        $('.loading').hide();
        console.log(error);
        this.failDialog("Invalid Username and Password");
      });
  }

  failDialog(msg){
    Swal.fire({
      type: 'error',
      title: 'รหัสพนักงาน หรือ รหัสผ่านไม่ถูกต้อง',
      text: msg
    })
  }
}
