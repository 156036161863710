import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;
declare var Swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService, AgencyService, ReportService } from 'src/app/shared';
import { TagInputModule } from 'ngx-chips';
import * as L from 'leaflet';
// กำหนดค่า Default Icon ใหม่
const DefaultIcon = L.icon({
  iconUrl: 'assets/marker-icon.png',
  shadowUrl: 'assets/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

// ตั้งค่าให้ใช้ Default Icon ใหม่
L.Marker.prototype.options.icon = DefaultIcon;

@Component({
  selector: 'app-report-inspection-round',
  templateUrl: './inspection-round.component.html',
  styleUrls: ['./inspection-round.component.css']
})
export class InspectionRoundComponent implements OnInit {
  map: L.Map | undefined;
  markers: L.Marker[] = [];
  polyline: L.Polyline;

  agencyList?: Array<any> = [];
  viewForm :FormGroup;
  eventTypeJson = [];
  options: any = {
    locale: { format: 'DD-MM-YYYY' },
    alwaysShowCalendars: false,
  };
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private agencyService: AgencyService,
    private reportService: ReportService,
    private commonService: CommonService
  ) {
    TagInputModule.withDefaults({
      tagInput: {
          placeholder: 'Enter a new condition',
          // add here other default values for tag-input
      },
      dropdown: {
          displayBy: 'my-display-value',
          // add here other default values for tag-input-dropdown
      }
    });

    this.viewForm =fb.group({
      'walkingInspectionIime':[''],
      'checkpoint':[''],
      'personalName':[''],
      'message':[''],
      'url1':[''],
      'url2':[''],
      'url3':[''],
      'latitude':[''],
      'longitude':[''],
    });

  }

  ngOnInit() {
    this.inspectionDay = this.commonService.convertDateToStrngDDMMYYYY(new Date())+" - "+this.commonService.convertDateToStrngDDMMYYYY(new Date());
    this.inspectionDayStart = this.commonService.convertDateToStrng(new Date());
    this.inspectionDayEnd = this.commonService.convertDateToStrng(new Date());
    this.search();
  }

  ngAfterViewInit() {

  }

  selectedInspectionDay(value: any, datepicker?: any) {
    // this is the date  selected
    console.log(value);
 
    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;
    let startDate = value.start._d;
    let endDate = value.end._d;
    
    this.inspectionDay = this.commonService.convertDateToStrngDDMMYYYY(startDate)+" - "+this.commonService.convertDateToStrngDDMMYYYY(endDate);
    this.inspectionDayStart = this.commonService.convertDateToStrng(startDate);
    this.inspectionDayEnd = this.commonService.convertDateToStrng(endDate);
  }

  inspectionDay;
  inspectionDayStart;
  inspectionDayEnd;
  isShowCalendarIn = true;
  showCalendarIn(){
    this.isShowCalendarIn = !this.isShowCalendarIn;
  } 

  agencyName
  deviceNameOrImei
  getRequestParams() {
    let params = {};

    if (this.agencyName) {
      params[`agencyName`] = this.agencyName;
    }

    if (this.deviceNameOrImei) {
      params[`deviceNameOrImei`] = this.deviceNameOrImei;
    }

    if (this.inspectionDayStart && this.isShowCalendarIn) {
      params[`inspectionDayStart`] = this.inspectionDayStart;
    }

    if (this.inspectionDayEnd && this.isShowCalendarIn) {
      params[`inspectionDayEnd`] = this.inspectionDayEnd;
    }

    params[`isShowCalendarIn`] = this.isShowCalendarIn;

    return params;
  }

  reportList = []
  search(){
    this.spinner.show();
    this.reportList = []
    $('#report_table').DataTable().clear().destroy();
    const params = this.getRequestParams();
    this.reportService.inspectionRound(params).subscribe(data => {
        this.reportList = data;
        console.log(this.reportList)
        setTimeout(()=>{
          $('#report_table').DataTable({});
        },500);
        this.spinner.hide();
    }, error => {
      this.failDialog('');
      this.spinner.hide();
      console.error(error);
    });
  }

  openViewDetail(data)
  {
    this.viewForm.patchValue({
      walkingInspectionIime: data.walkingInspectionIime,
      checkpoint: data.checkpoint,
      personalName: data.personalName,
      message: data.message,
      url1: data.url1,
      url2: data.url2,
      url3: data.url3,
      latitude: data.latitude,
      longitude: data.longitude,
    });
    $('#modal-view').modal('show');
  }

  latitude!: number;
  longitude!: number;
  popupText;
  roundList =  [];
  openViewLocation(data)
  {
    this.completeResetMap();
    console.log(data)
    this.roundList = data.roundList;
    if(data.roundList.length > 0){
      this.latitude = data.roundList[0].latitude
      this.longitude = data.roundList[0].longitude
      this.popupText = data.roundList[0].checkpoint
    }
    $('#modal-view-location').modal('show');
    setTimeout(() => {
      this.initMap();
      setTimeout(() => {
        this.addMarkers();
      }, 500);
    }, 500);
  }

  public completeResetMap() {
    // ทำลายแผนที่เดิม
    if (this.map) {
      this.removeAll();
      this.map.remove();
      console.log('completeResetMap');
    }
  }

  private addMarkers() {
    console.log(this.roundList)
    this.roundList.forEach(data => {
      this.addMarker(data.latitude, data.longitude, data.checkpoint);
    });
  }

  private initMap() {
    // กำหนดค่าเริ่มต้นของแผนที่
    this.map = L.map('map', {
      center: [this.latitude, this.longitude],
      zoom: 14
    });
    
    // เพิ่ม tile layer
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    
    tiles.addTo(this.map);

    this.polyline = L.polyline([], {
      color: 'blue',
      weight: 3,
      opacity: 0.7,
      lineJoin: 'round'
    }).addTo(this.map);
    
    // ตัวอย่างเพิ่ม marker เริ่มต้น
    this.addMarker(this.latitude, this.longitude, this.popupText);

    // เพิ่มความสามารถในการคลิกเพื่อเพิ่ม marker
    // this.map.on('click', (e: L.LeafletMouseEvent) => {
    //   const coord = e.latlng;
    //   const lat = coord.lat;
    //   const lng = coord.lng;
    //   console.log(`เพิ่ม marker ที่: ${lat}, ${lng}`);
    //   this.addMarker(lat, lng, `จุดที่เลือก (${lat.toFixed(5)}, ${lng.toFixed(5)})`);
    // });
  }

  // เมธอดสำหรับเพิ่ม marker ใหม่
  public addMarker(lat: number, lng: number, popupText: string = 'Marker ใหม่'): void {
    const marker = L.marker([lat, lng], {
      draggable: false  // ให้สามารถลากได้ = true
    }).addTo(this.map);
    
    marker.bindPopup(popupText).openPopup();
    this.markers.push(marker);
    
    // เพิ่มจุดพิกัดใหม่เข้าไปในเส้น polyline
    this.updatePolyline();
    
    // เพิ่ม event listener เมื่อลาก marker
    marker.on('dragend', () => {
      this.updatePolyline();
    });
    
    // ปรับมุมมองแผนที่ให้มองเห็นทั้งหมด
    if (this.markers.length > 1) {
      const group = new L.FeatureGroup(this.markers);
      this.map.fitBounds(group.getBounds(), {
        padding: [30, 30]
      });
    } else {
      this.map.panTo(new L.LatLng(lat, lng));
    }
  }

  private updatePolyline(): void {
    const latlngs = this.markers.map(marker => marker.getLatLng());
    this.polyline.setLatLngs(latlngs);
  }

  private removeAll(): void {
    if (this.polyline) {
      this.map.removeLayer(this.polyline);
      this.polyline = null;
    }
    this.markers.forEach(marker => this.map.removeLayer(marker));
    this.markers = [];
  }

  // เมธอดสำหรับลบ marker และเส้น polyline ทั้งหมด
  public clearMarkers(): void {
    this.markers.forEach(marker => {
      this.map.removeLayer(marker);
    });
    this.markers = [];
    
    // ล้างเส้น polyline
    this.polyline.setLatLngs([]);
  }

  successDialog() {
    Swal.fire("ทำรายการสำเร็จ!", "", "success");
  }

  failDialog(msg) {
    Swal.fire({
      type: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: msg
    })
  }

}
