import { SurveyRoundModule } from "./survey-round/survey-round.model";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers';
import {
  HeaderComponent,
  MenuLeftComponent,
  LoginInitComponent,
  FooterComponent,
  Constant,
} from "./shared";
import { DashboardModule } from "./dashboard/dashboard.module";
import { AgencyModule } from "./setting/agency/agency.module";
import { DeviceListModule } from "./setting/device/list/device-list.module";
import { PointCardModule } from "./setting/point/point.module";
import { ReportAllModule } from "./report/all/all.module";
import { InspectionRoundModule } from "./report/inspection-round/inspection-round.module";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuLeftComponent,
    FooterComponent,
    LoginInitComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    DeviceListModule,
    PointCardModule,
    SurveyRoundModule,
    ReportAllModule,
    InspectionRoundModule,
    AgencyModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Constant
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
